import React from 'react'
import { Parallax } from 'react-parallax'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import {
  about,
  aboutParallax,
  foodGallery,
  mobileAbout,
  mobileGallery,
} from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView>
          <CFImage src={mobileAbout} w="100%" alt="Mobile About" />
          <CFImage src={mobileGallery} w="100%" alt="Mobile About" />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center maxWidth="1400px">
          <CFImage src={about} w="100%" alt="About" />
          <CFImage src={foodGallery} w="100%" alt="About" mb="15px" />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
